//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px; // Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px; // Extra small devices (portrait phones, less than 576px)

//-------------------- Responsive --------------------//
@mixin desktop {
  @media (max-width: #{$large-device-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$medium-device-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$small-device-width}) {
    @content;
  }
}

@mixin extra-small-mobile {
  @media (max-width: #{$extra-small-device-width}) {
    @content;
  }
}
@mixin tablet-with-laptop {
  @media (max-width: #{$small-device-width}) and (max-width: #{$medium-device-widthwidth}) {
    @content;
  }
}

//-------------------- End Responsive --------------------//
@media (max-width: 620px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
}

@media (max-width: 419px) {
}

@media (min-width: 768px) {
}
