@import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Hubballi&family=Nunito:wght@200&family=Roboto:ital,wght@1,100;1,500&display=swap");

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  font-family: $font-family;
}

.ant-btn,
.ant-btn-default {
  background: $primary;
  border: 1px solid $primary;
  color: white;
  min-width: 120px;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn-default:hover,
.ant-btn-default:focus {
  background: $primary !important;
  border-color: $primary !important;
  color: white !important;
  min-width: 120px;
}

.ant-picker-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  outline: none;
}
.ant-select {
  &:focus {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    outline: none;
  }
  .ant-select-selector:focus {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    outline: none;
  }
}
.ant-select-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  outline: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary !important;
  border-color: $primary !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary !important;
}

.user-image {
  border: 2px solid #ff9a3e;
  border-radius: 50%;
}

.property-img-border {
  border: 2px solid #ff9a3e;
}

.ant-picker {
  border-radius: 8px !important;
}

.ant-input:hover,
.ant-input:focus,
.ant-picker:hover,
.ant-input-number-input:hover,
.ant-input-number-input:focus,
.ant-picker:focus,
.ant-picker-focused:focus,
.ant-picker-focused:focus,
.ant-input-password:hover,
.ant-input-password:focus,
.ant-select:hover,
.ant-select:focus,
.ant-input-affix-wrapper:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  outline: none !important;
}

.ant-input-number:hover,
.ant-input-number:focus {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
  outline: 0;
}
.ant-input-affix-wrapper {
  box-shadow: none;
  border-color: #d9d9d9 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $primary;
  color: $white;
}

.form-card-border {
  border-radius: 10px 10px 0 0;

  .ant-card-head {
    background: #efefef;
    border-bottom: 1px solid #443527;
    max-height: 25px;
  }
  .user-name {
    font-size: 25px;
    font-weight: 500;
    line-height: 29.3px;
    color: #000000;
    margin-bottom: 0;
  }
  .user-email {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    color: #000000;
    margin-top: 5px;
  }

  .ant-card-head-title {
    font-size: 20px;
  }
}

.list-layout-li .ant-list-item {
  padding: 2px 20px !important;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-content {
  display: flex;
  align-items: baseline !important;
}
.custom-layout {
  .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-content {
    .ant-list-item-meta-title {
      margin: 0;
    }
    display: flex !important;
    // justify-content: space-between !important;
    align-items: center !important;
    gap: 20px;
  }
}
.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #443527;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ff9a3e;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  white-space: nowrap;
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #443527;
  white-space: nowrap;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #443527;
  white-space: nowrap;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #443527;
}

// .ant-input-lg {
//   background: #FCFCFC;
//   border-radius: 0px;
//   border: 1.33115px solid #CECAC6;
// }
.ant-form-item {
  margin-bottom: 8px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #ff9a3e;
  border-color: #ff9a3e;
}

.ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: #ff9a3e;
}

.loader-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-upload-list-item-thumbnail img {
  object-fit: cover !important;
}

// :where(.css-dev-only-do-not-override-1me4733).ant-pagination .ant-pagination-item-active {
//   border-color: #ff9a3e;
// }

// :where(.css-dev-only-do-not-override-1me4733).ant-pagination .ant-pagination-item-active a{
//   color: #ff9a3e;
// }

.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

// toggelbutton css
.ant-switch.ant-switch-checked {
  background: #ff9a3e;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #ff9a3e;
}
.favourite-property-card {
  .ant-card-body {
    padding: 2px 0px;
    text-align: left;
    .ant-list-items {
      // padding: 10px 0px;
      .ant-list-item {
        padding: 10px 20px !important;
        justify-content: left;
        a {
          color: #000000;
          font-weight: 500;
          font-size: 13px;
        }
        a:hover {
          color: #ff9a3e;
        }
        span {
          color: #000000;
          font-size: 17px;
          font-weight: 500;
          p {
            color: #443527;
          }
        }
      }
    }
  }
}

/* Scrollbar Styling */
// ::-webkit-scrollbar {
//   width: 5px;
// }

// ::-webkit-scrollbar-track {
//   background-color: #ebebeb;
//   -webkit-border-radius: 10px;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//   -webkit-border-radius: 10px;
//   border-radius: 10px;
//   background: #6d6d6d;
// }
.scrollable-list::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
}

.scrollable-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.scrollable-list::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 5px; /* Rounded corners */
}

.scrollable-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

.ant-spin .ant-spin-dot-item {
  background-color: #ff9a3e;
}
.owner-delete-button {
  min-width: 60px !important;
  span {
    color: white !important;
    font-size: 13px !important;
    font-weight: 500;
  }
}
.owner-delete-button:hover {
  min-width: 60px;
}
:where(.css-dev-only-do-not-override-190m0jy).ant-select-status-error:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #d9d9d9 !important;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-input-status-error:not(
    .ant-input-disabled
  ):not(.ant-input-borderless).ant-input {
  border-color: #d9d9d9 !important;
}

// pagination style
.ant-pagination .ant-pagination-item-active a {
  color: #ff9a3e;
}
.ant-pagination .ant-pagination-item-active {
  border-color: #ff9a3e;
}
.ant-pagination .ant-pagination-item-active:hover {
  border-color: #ff9a3e;
}
.ant-pagination .ant-pagination-item-active:hover a {
  color: #ff9a3e;
}

.custom-checkbox {
  .ant-checkbox-wrapper {
    width: 100%;
    padding: 3px 0;
  }
}

.flex {
  display: flex;
  justify-content: start;
  gap: 0 20px;
  align-items: center;
}
h3 {
  // letter-spacing: 1px;
  // font-size: 20px;
  // margin: 0;
  font-size: 20px;
  cursor: pointer;
}

.line-bottom {
  position: relative;

  margin: 0;
  cursor: pointer;
}

.line-bottom::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50%;
  margin: auto;
  border-radius: 10px;
  height: 4px; /* Adjust as needed */
  background-color: #ff9a3e; /* Adjust color as needed */
}
.business-u-card {
  .ant-card-head {
    max-height: 30px !important;
  }
}

.website-link {
  color: black;
}
.lead-card {
  margin: 10px 0;
  margin-right: 10px;
  .ant-card-body {
    padding: 0 10px !important;
  }
} /* 
.lead-customer{
  height: 400px;
  overflow: auto !important;
} */
.lead-customer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.lead-customer::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.lead-customer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(30, 41, 59, 0.4);
}

.lead-modal {
  .ant-modal-content {
    max-height: 460px !important;
    padding: 0;
    overflow: hidden !important;
  }
  .ant-modal-body {
    overflow: auto !important;
    max-height: 460px !important;
    padding: 6px 24px;
    // margin-right: 10px;
    padding-bottom: 100px !important ;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(30, 41, 59, 0.4);
  }
}

.table-scrollable-list {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(30, 41, 59, 0.4);
  }
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  color: #ff9a3e;

  .ant-card-body {
    padding: 0px !important;
    border-radius: 0 0 8px 8px;
  }

  .tab-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;

    .tab-item {
      flex: 1;
      text-align: center;
      font-size: 7px;
      font-weight: 500;
      cursor: pointer;
      color: #ff9a3e;

      &.selected {
        color: #ff9a3e;
        border-bottom: 2px solid #ff9a3e;
      }

      h1 {
        margin-bottom: 7px;
      }
    }
  }
}

.content {
  padding: 5px;
}

.subscription-plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 16px;
  background: #fff;

  margin: auto;
}

/* Left Section: Monthly Plan + Active Tag */
.subscription-left {
  display: flex;
  align-items: center;
}

.plan-title {
  display: flex;
  align-items: center;
}

.plan-icon {
  width: 16px;
  height: 16px;
}

.plan-name {
  margin-left: 2px;
  font-weight: bold;
  font-size: 13px;
}

.plan-status {
  margin-left: 10px;
  padding: 3px 8px;
  border-radius: 50px;
  background: #fffae6;
  border: 1px solid #ff9a3e;
  color: #ff9a3e;
}

.subscription-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.cancel-subscription {
  display: flex;
  align-items: center;
  color: #777777;
}

.cancel-label {
  margin-left: 2px;
  font-size: 11px;
}

.action-buttons {
  display: flex;
  gap: 8px; /* Space between buttons */
  justify-content: space-between; /* Even spacing */
}

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 4px 8px;
  height: 28px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  color: #767676;
  border-radius: 4px;
}

.custom-button:hover {
  background-color: #f5f5f5;
  color: #565656;
  border-color: #c0c0c0;
}

.button-icon {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}

.date-expairy {
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.pack-expiry {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 7px;
}

.expiry-icon {
  width: 45px;
  height: 45px;

  border-radius: 50%;
  padding: 8px;
  object-fit: contain;
}

.expiry-text {
  display: flex;
  flex-direction: column;
}

.expiry-label {
  font-size: 11px;
  color: #777;
  font-weight: bold;
}

.expiry-date {
  font-size: 11px;
  font-weight: bold;
  color: #333;
  margin: 0;
}
.plan-container {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-details {
  display: flex;
  align-items: center;
}

.plan-info {
  display: flex;
  align-items: center;
}

.plan-icon {
  margin-right: 3px;
}

.plan-title {
  font-weight: bold;
}

.custom-divider {
  height: 10px !important;
  margin-top: 25px;
}

.date-expairy-custom {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}
